import React, { useState, useEffect, useCallback } from 'react';

function HeaderSidebar() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = useCallback(() => {
    const mobile = window.innerWidth < 768;
    setIsMobile(mobile);

    if (mobile) {
      setIsSidebarVisible(false);
      setIsOverlayVisible(false);
    } else {
      setIsOverlayVisible(false);
      setIsSidebarVisible(true);
    }
  }, []);

  useEffect(() => {
    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const toggleSidebar = useCallback(() => {
    setIsSidebarVisible(prev => !prev);
    if (isMobile) {
      setIsOverlayVisible(prev => !prev);
    }
  }, [isMobile]);

  const closeSidebar = () => {
    if (isMobile) {
      setIsSidebarVisible(false);
      setIsOverlayVisible(false);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (window.innerWidth < 768) {
      const menuToggle = document.getElementById('menu-toggle');
      const isClickInsideMenuToggle = menuToggle && menuToggle.contains(event.target);
      const sidebar = document.getElementById('sidebar');
      const isClickInsideSidebar = sidebar && sidebar.contains(event.target);

      if (!isClickInsideSidebar && !isClickInsideMenuToggle && isSidebarVisible) {
        setIsSidebarVisible(false);
        setIsOverlayVisible(false);
      }
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const navigateTo = (path) => {
    window.location.href = path;
    closeSidebar();
  };

   return (
    <>
      <header>
        <div className="header-content">
          <button id="menu-toggle" onClick={toggleSidebar}>☰</button>
          <div className="logo">HendyStat</div>
          <div className="spacer"></div>
        </div>
      </header>
      
      <div className={`overlay ${isOverlayVisible ? 'active' : ''}`} onClick={closeSidebar}></div>
      
      <nav style={{ left: isSidebarVisible ? '0px' : '-200px' }}>
        <ul>
          {/* Market Returns Section */}
          <li className="section-header">Market Returns</li>
          <li><a href="/returns" onClick={(e) => { e.preventDefault(); navigateTo('/returns'); }}>Overview</a></li>
          <li><a href="/returns/#/historicals" onClick={(e) => { e.preventDefault(); navigateTo('/returns/#/historicals'); }}>Market-Implied Statistics</a></li>
          <li><a href="/returns/#/allocation" onClick={(e) => { e.preventDefault(); navigateTo('/returns/#/allocation'); }}>Optimal Equity Allocation</a></li>
          <li><a href="/returns/#/theory" onClick={(e) => { e.preventDefault(); navigateTo('/returns/#/theory'); }}>Theory</a></li>
          
          {/* Corporate Performance Section */}
          <li className="section-header">Corporate Performance</li>
          <li><a href="/corporate-history" onClick={(e) => { e.preventDefault(); navigateTo('/corporate-history'); }}>Historical P&L</a></li>
        </ul>
      </nav>
    </>
  );
}

export default HeaderSidebar;