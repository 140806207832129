import React from 'react';
import HeaderSidebar from './components/HeaderSidebar';
import Dashboard from './components/Dashboard';
import './styles/header-sidebar.css';

function App() {
  return (
    <>
      <HeaderSidebar />
      <main>
        <Dashboard />
      </main>
    </>
  );
}

export default App;