import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../components/ui/card';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { ChevronRight } from 'lucide-react';
import routes from '../config/routes';
import { getCorporateDataUrl, parseCSVData, processCorporateData } from '../utils/corporateDataUtils';

const getDataUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3001/erp_latest.txt';
  }
  return '/returns/erp_latest.txt';
};

const formatMetricValue = (metric) => {
  // Risk Aversion shows actual value, not percentage
  if (metric.label === "Risk Aversion") {
    return metric.value.toFixed(2);
  }
  // All other metrics show as percentages
  return (metric.value * 100).toFixed(2) + '%';
};

const formatMetricChange = (metric) => {
  // Format the change value
  let changeValue;
  if (metric.label === "Risk Aversion") {
    // Risk Aversion shows absolute change
    changeValue = metric.change.toFixed(3);
  } else {
    // Other metrics show change in basis points
    changeValue = `${(metric.change * 10000).toFixed(0)} bps`;
  }

  // Determine color based on metric type and direction
  let isPositiveGood;
  if (metric.label === "Risk Aversion" || 
      metric.label === "Real Expected Equity Return" || 
      metric.label === "Nominal Expected Equity Return" ||
      metric.label === "Equity Risk Premium") {
    isPositiveGood = false;  // Up is bad (red), down is good (green)
  } else {
    isPositiveGood = true;   // Up is good (green), down is bad (red)
  }

  const colorClass = (metric.change > 0) === isPositiveGood ? 'text-green-500' : 'text-red-500';
  const sign = metric.change > 0 ? '+' : '';

  return (
    <span className={`text-sm ml-2 ${colorClass}`}>
      ({sign}{changeValue})
    </span>
  );
};




const formatPercentage = (value) => (value * 100).toFixed(2);
const formatChange = (value) => (value * 100).toFixed(2);



const NavigationCard = ({ title, description, href, isExternal = false }) => (
  <Card className="hover:shadow-lg transition-all cursor-pointer" onClick={() => isExternal ? window.open(href, '_blank') : window.location.href = href}>
    <CardContent className="pt-4">  {/* Change from p-6 to p-8 */}
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-semibold mb-2">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>
        <ChevronRight className="h-6 w-6 text-blue-600" />
      </div>
    </CardContent>
  </Card>
);

const MetricRow = ({ label, current, quarterChange, yearChange, format }) => {
  const formatValue = (value) => {
    if (value === undefined || value === null) return '-';
    if (format === 'currency') return `$${value.toFixed(1)}T`;
    if (format === 'percentage') return `${value.toFixed(1)}%`;
    return value.toFixed(1);
  };

  const formatChange = (value) => {
    if (value === undefined || value === null) return '-';
    return <span className={`${value > 0 ? 'text-green-500' : 'text-red-500'}`}>
      {value > 0 ? '+' : ''}{value.toFixed(1)}%
    </span>;
  };

  return (
    <div className="grid grid-cols-4 gap-4 py-3 border-b border-gray-100">
      <div className="font-medium">{label}</div>
      <div className="text-right font-semibold">{formatValue(current)}</div>
      <div className="text-right">{formatChange(quarterChange)}</div>
      <div className="text-right">{formatChange(yearChange)}</div>
    </div>
  );
};

const Dashboard = () => {
  const [marketData, setMarketData] = useState(null);
  const [error, setError] = useState(null);
  const [corporateData, setCorporateData] = useState(null);

  useEffect(() => {
  // Market data fetch
  fetch(getDataUrl())
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Fetched data:', data);
      setMarketData(data);
    })
    .catch(error => {
      console.error('Error fetching market data:', error);
      setError(error.message);
    });

  // Corporate data fetch
  fetch(getCorporateDataUrl())
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.text();
    })
    .then(csvText => {
      const parsedData = parseCSVData(csvText);
      const processedData = processCorporateData(parsedData);
      setCorporateData(processedData);
    })
    .catch(error => {
      console.error('Error fetching corporate data:', error);
      setError(error.message);
    });
}, []);

if (!marketData || !corporateData) {
  return <div className="min-h-screen bg-gray-50 p-6">Loading data...</div>;
}

  if (error) {
    return <div className="min-h-screen bg-gray-50 p-6">Error loading market data: {error}</div>;
  }

  const metrics = [
    {
      label: "Nominal Expected Equity Return",
      value: marketData.er_nom.last,
      change: marketData.er_nom.change
    },
    {
      label: "Real Expected Equity Return",
      value: marketData.er.last,
      change: marketData.er.change
    },
    {
      label: "Equity Risk Premium",
      value: marketData.erp.last,
      change: marketData.erp.change
    },
    {
      label: "Risk Aversion",
      value: marketData.risk_aversion.last,
      change: marketData.risk_aversion.change
    },
    {
      label: "Expected Growth",
      value: marketData.log_growth.last,
      change: marketData.log_growth.change
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {/* Market Implied Expected Returns Section */}
        <section className="mb-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Market Implied Expected Returns</h2>
            <div className="text-sm text-gray-500">
              Last updated: {new Date(marketData.erp.last_time).toLocaleString()}
            </div>
          </div>

          {/* Current Metrics Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
            {metrics.map((metric, index) => (
              <Card key={index} className="bg-white">
                <CardContent className="pt-6">
                  <div className="text-sm text-gray-600 mb-1">{metric.label}</div>
                  <div className="text-2xl font-bold">
                    {formatMetricValue(metric)}
                    {formatMetricChange(metric)}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
          {/* Navigation Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <NavigationCard
              title="Theory"
              description="Understanding the economic model and assumptions"
              href={routes.marketReturns.theory}
              isExternal={false}
            />
            <NavigationCard
              title="Historical Data"
              description="Detailed historical trends and analysis"
              href={routes.marketReturns.historicals}
              isExternal={false}
            />
            <NavigationCard
              title="Optimal Equity Allocation"
              description="Portfolio implications and allocation strategies"
              href={routes.marketReturns.allocation}
              isExternal={false}
            />
          </div>
        </section>

        {/* US Aggregate Corporate Performance Section */}
        {/* US Aggregate Corporate Performance Section */}
        <section>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">US Aggregate Corporate Performance</h2>
          </div>

          {corporateData ? (
            <>
              <Card className="mb-6">
                <CardContent className="pt-6">
                  {/* Header */}
                  <div className="grid grid-cols-4 gap-4 pb-2 border-b border-gray-200 mb-2">
                    <div className="font-semibold">Metric</div>
                    <div className="text-right font-semibold">{corporateData.currentQuarter}</div>
                    <div className="text-right font-semibold">QoQ Change</div>
                    <div className="text-right font-semibold">YoY Change</div>
                  </div>

                  {/* Metrics */}
                  <MetricRow
                    label="Gross Value Added"
                    current={corporateData.metrics.grossValueAdded.current}
                    quarterChange={corporateData.metrics.grossValueAdded.quarterGrowth}
                    yearChange={corporateData.metrics.grossValueAdded.yearGrowth}
                    format="currency"
                  />
                  <MetricRow
                    label="Profits After Tax"
                    current={corporateData.metrics.profitsAfterTax.current}
                    quarterChange={corporateData.metrics.profitsAfterTax.quarterGrowth}
                    yearChange={corporateData.metrics.profitsAfterTax.yearGrowth}
                    format="currency"
                  />
                  <MetricRow
                    label="Profits % of GVA"
                    current={corporateData.metrics.profitsPercent.current}
                    quarterChange={corporateData.metrics.profitsPercent.quarterChange}
                    yearChange={corporateData.metrics.profitsPercent.yearChange}
                    format="percentage"
                  />
                </CardContent>
              </Card>

              {/* Corporate Navigation Card */}
              <div className="grid grid-cols-1">
                <NavigationCard
                  title="Full Historical P&L"
                  description="Complete time series of aggregate corporate performance metrics"
                  href={routes.corporate.history}
                  isExternal={false}
                />
              </div>
            </>
          ) : (
            <Card className="mb-6">
              <CardContent className="pt-6">
                <div className="text-center text-gray-500">Loading corporate data...</div>
              </CardContent>
            </Card>
          )}
        </section>
      </div>
    </div>
  );
};

export default Dashboard;