export const getCorporateDataUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3002/data/corporate_business_metrics.csv';
  }
  return '/corporate-history/data/corporate_business_metrics.csv';
};

// Function to parse CSV data
export const parseCSVData = (csvText) => {
  const lines = csvText.trim().split('\n');
  const headers = lines[0].split(',');
  
  return lines.slice(1).map(line => {
    const values = line.split(',');
    const row = {};
    headers.forEach((header, index) => {
      row[header] = values[index] ? parseFloat(values[index]) : null;
    });
    // Add the raw date string to preserve the format
    row.date = values[0];
    return row;
  });
};

// Helper function to check if a row has complete data for required metrics
const hasCompleteData = (row) => {
  return row.gross_value_added != null && 
         row.profits_after_tax_with_iva_ccadj != null;
};

// Helper function to get the quarter from a date string (YYYY-MM-DD)
const getQuarter = (dateStr) => {
  const [year, month] = dateStr.split('-').map(Number);
  return Math.floor((month - 1) / 3) + 1;
};

// Helper function to format quarter label (e.g., "2024-Q1")
export const getQuarterLabel = (dateStr) => {
  const [year] = dateStr.split('-');
  const quarter = getQuarter(dateStr);
  return `${year}-Q${quarter}`;
};

// Calculate Compound Annual Growth Rate
const calculateCAGR = (startValue, endValue, timePeriodInYears) => {
  if (!startValue || !endValue || startValue <= 0 || endValue <= 0) return null;
  return (Math.pow(endValue / startValue, 1 / timePeriodInYears) - 1) * 100;
};

// Process corporate data into dashboard format
export const processCorporateData = (data) => {
  // Filter for complete rows and sort by date in ascending order
  const validData = data
    .filter(hasCompleteData)
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
  
  // Get the quarters
  const priorYear = validData[validData.length - 5];  // 4 quarters before current
  const priorQuarter = validData[validData.length - 2]; // Previous quarter
  const currentQuarter = validData[validData.length - 1]; // Most recent quarter

  // Convert to billions and calculate CAGRs
  const result = {
    currentQuarter: getQuarterLabel(currentQuarter.date),
    metrics: {
      grossValueAdded: {
        current: currentQuarter.gross_value_added / 1000,
        priorQuarter: priorQuarter.gross_value_added / 1000,
        priorYear: priorYear.gross_value_added / 1000,
        quarterGrowth: calculateCAGR(
          priorQuarter.gross_value_added,
          currentQuarter.gross_value_added,
          0.25  // One quarter = 0.25 years
        ),
        yearGrowth: calculateCAGR(
          priorYear.gross_value_added,
          currentQuarter.gross_value_added,
          1.0  // One year
        )
      },
      profitsAfterTax: {
        current: currentQuarter.profits_after_tax_with_iva_ccadj / 1000,
        priorQuarter: priorQuarter.profits_after_tax_with_iva_ccadj / 1000,
        priorYear: priorYear.profits_after_tax_with_iva_ccadj / 1000,
        quarterGrowth: calculateCAGR(
          priorQuarter.profits_after_tax_with_iva_ccadj,
          currentQuarter.profits_after_tax_with_iva_ccadj,
          0.25
        ),
        yearGrowth: calculateCAGR(
          priorYear.profits_after_tax_with_iva_ccadj,
          currentQuarter.profits_after_tax_with_iva_ccadj,
          1.0
        )
      },
      profitsPercent: {
        current: (currentQuarter.profits_after_tax_with_iva_ccadj / currentQuarter.gross_value_added) * 100,
        priorQuarter: (priorQuarter.profits_after_tax_with_iva_ccadj / priorQuarter.gross_value_added) * 100,
        priorYear: (priorYear.profits_after_tax_with_iva_ccadj / priorYear.gross_value_added) * 100,
        quarterChange: ((currentQuarter.profits_after_tax_with_iva_ccadj / currentQuarter.gross_value_added) - 
                       (priorQuarter.profits_after_tax_with_iva_ccadj / priorQuarter.gross_value_added)) * 100,
        yearChange: ((currentQuarter.profits_after_tax_with_iva_ccadj / currentQuarter.gross_value_added) - 
                    (priorYear.profits_after_tax_with_iva_ccadj / priorYear.gross_value_added)) * 100
      }
    }
  };

  // For debugging
  console.log('Current Quarter:', currentQuarter.date, '→', getQuarterLabel(currentQuarter.date));
  console.log('Prior Quarter:', priorQuarter.date, '→', getQuarterLabel(priorQuarter.date));
  console.log('Prior Year Quarter:', priorYear.date, '→', getQuarterLabel(priorYear.date));

  return result;
};