const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  }
  return 'https://hendystat.com';
};

const BASE_URL = getBaseUrl();

const routes = {
  marketReturns: {
    theory: `${BASE_URL}/returns/#/theory`,
    historicals: `${BASE_URL}/returns/#/historicals`,
    allocation: `${BASE_URL}/returns/#/allocation`
  },
  corporate: {
    history: `${BASE_URL}/corporate-history`
  }
};

export default routes;  // This is a default export